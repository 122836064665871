<template>
<div class="one_paragraph" @click="go_to">
  <div class="header">
    <span>《{{ info.book_name }}》</span>
    <span>{{ info.chapter_title }}</span>
  </div>
  <!-- {{info.content}} -->
  <div class="">
    <span v-for="(conetnt, index) in info.contents" :key="index" :class="[conetnt.isKeyword ? 'keyword' : '']">{{ conetnt.word }}</span>
  </div>
  <OneBookParagraph :paragraph_info="paragraph"  style="margin: 20px 0px;"/>

</div>
</template>
<script>

export default {
  name: 'OneBookParagraphSearch',
  props: {
    paragraph_info: Map
  },
  data () {
    return {
      info: this.paragraph_info
    }
  },
  mounted () {
    this.init_data()
  },
  methods: {
    go_to (e) {
      const routeData = this.$router.resolve({ name: 'Book_chapter', params: { id: this.info.chapter_id } })
      window.open(routeData.href, '_blank')
    },
    init_data () {
      // console.log(222)
      // this.info.contents = []
      this.info.contents = this.convertToKeywordList(this.info.content, this.info.keywords)
    },
    convertToKeywordList (content, keywords) {
      if (!keywords) {
        return [{ word: content, isKeyword: false }]
      }
      const regexPattern = keywords.map(keyword => keyword.replace(/[\u4e00-\u9fa5]/g, '\\$&')).join('|')
      const regex = new RegExp(regexPattern, 'g')
      const matches = [...content.matchAll(regex)]

      const result = []
      let lastIndex = 0
      for (const match of matches) {
        const word = content.slice(lastIndex, match.index)
        if (word) {
          result.push({ word: word, isKeyword: false })
        }
        result.push({ word: match[0], isKeyword: true })
        lastIndex = match.index + match[0].length
      }

      if (lastIndex < content.length) {
        const word = content.slice(lastIndex)
        result.push({ word: word, isKeyword: false })
      }
      return result
    }
  }
}
</script>

<style scoped>
.one_paragraph {
  color: #555;
  margin: 10px 20px 10px 0px;
  cursor: pointer;
}
.header{
  color: #50b7c1;
}
.keyword{
  color: red;
}
</style>
