<template>
<router-link target="_blank" :to="{ name: 'Book_general', params: { id: info.id }}">
  <div class="one_book">
    <span class="title"> {{info.name}}</span>
  </div>
</router-link>
</template>
<script>
// import router from '@/router/index.js'

export default {
  name: 'OneBook',
  props: {
    book_info: Map
  },
  data () {
    return {
      info: this.book_info
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.one_book {
  color: #999;
  margin: 0px 6px 5px 0px;
  display: inline-block;
  cursor:pointer;
  font-size: 14px;
}
.title{
  color: #50b7c1;
  /* font-weight: bold; */
  /* line-height: 20px; */
  vertical-align: middle;

}

</style>
